import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import PageWrapper from '../components/PageWrapper';
import Treatment from '../sections/blog/blogtreat.js';
import Trend from '../components/trends.js';
import Saveface from '../components/save.js';
import Himage from '../assets/image/jpeg/home-bg2.jpg';
import styled from 'styled-components';
import { Box, Section } from "../components/Core";


const Secti = styled.section`
  position: relative;
  background-color: #f7f7fb;
  @media screen and (max-width: 767px) {
    padding-top: 2em;
  }

`;

const Iwrap = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
  
  }
`;

const Iline = styled.div`
  width: 6em;
  height: 1px;
  margin-right: 2em;
  background-color: hsla(0, 0%, 100%, 0.8);
  @media screen and (max-width: 991px) {
    width: 3em;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400;
  font-style: oblique;
  @media screen and (max-width: 991px) {
    font-size: 10px;
  }
`;

const Imagi = styled.div`
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background: url(${Himage}) no-repeat;
  background-size: cover;
  background-position: center center;
  background-position: 0px 0px;
  opacity: 0.1;
`;

const Secondtext = styled.h1`

  font-size: 2em;
  font-smooth: always;
  color: #00aec7;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    
   
  }
`;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        date
        author
        image
        category
        keywords
        images
        faqs {
          question
          answer
        }
      }
      html
      fields {
        slug
      }
    }
  }
`;

const BlogPost = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": data.markdownRemark.fields.slug,
    },
    "headline": frontmatter.title,
    "description": frontmatter.description || 'Default description',
    "image": frontmatter.images || ['https://example.com/default-image.jpg'],
    "datePublished": frontmatter.date,
    "dateModified": frontmatter.date,
    "author": {
      "@type": "Person",
      "name": frontmatter.author || 'Default Author', // Optional with default
    },
    "publisher": {
      "@type": "Organization",
      "name": "Dermamina",
      "logo": {
        "@type": "ImageObject",
        "url": "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhLS0gQ3JlYXRlZCB3aXRoIElua3NjYXBlIChodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy8pIC0tPgoKPHN2ZwogICB3aWR0aD0iMjgyLjQ4NzdtbSIKICAgaGVpZ2h0PSIzMC43MzMwMDdtbSIKICAgdmlld0JveD0iMCAwIDI4Mi40ODc3IDMwLjczMzAwNyIKICAgdmVyc2lvbj0iMS4xIgogICBpZD0ic3ZnOCIKICAgc29kaXBvZGk6ZG9jbmFtZT0iZGVybWFtaW5hLWxvZ28uc3ZnIgogICBpbmtzY2FwZTp2ZXJzaW9uPSIxLjIuMiAoNzMyYTAxZGE2MywgMjAyMi0xMi0wOSkiCiAgIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIgogICB4bWxuczpz...==" // Replace with your Base64-encoded logo URL
      },
    },
    "articleSection": frontmatter.category || 'Default Category', // Optional with default
    "keywords": frontmatter.keywords || ['default', 'keywords'], // Optional with default
    "mainEntity": {
      "@type": "FAQPage",
      "mainEntity": frontmatter.faqs ? frontmatter.faqs.map(faq => ({
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer,
        },
      })) : [], // Handle missing faqs
    },
  };

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        {frontmatter.description && (
          <meta name="description" content={frontmatter.description} />
        )}
        {frontmatter.keywords && (
          <meta name="keywords" content={frontmatter.keywords.join(', ')} />
        )}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <PageWrapper>
        <Secti>
          <Section py={4} className="pt-lg-5">
            <Container className='pt-4'>
              <Iwrap className='pt-lg-5'>
                <Itext>{frontmatter.date}</Itext>
              </Iwrap>
              <Secondtext>{frontmatter.title}</Secondtext>
            </Container>
          </Section>
        </Secti>
        <Section py={0}>
          <Container>
            <Row>
              <Col lg="8">
              <div className="blogcontent">
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
              </Col>
              <Col lg="4">
                <Treatment />
              </Col>
            </Row>
          </Container>
        </Section>
        <Saveface />
        <Trend />
      </PageWrapper>
    </>
  );
};

export default BlogPost;
