import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span } from "../../components/Core";
import imgC1 from "../../assets/image/jpeg/surgical-nose.jpg";
import imgC2 from "../../assets/image/jpeg/beard.jpg";
import imgC3 from "../../assets/image/jpeg/laser-main.jpg";
import imgC4 from "../../assets/image/jpeg/vampire-facial.jpg";
import imgC5 from "../../assets/image/jpeg/anti.jpg";
import imgC6 from "../../assets/image/jpeg/tear.jpg";
import imgC7 from "../../assets/image/jpeg/hydrabg.jpg";
import imgC8 from "../../assets/image/jpeg/prp.jpg";
import imgC9 from "../../assets/image/jpeg/eyelift.jpg";
import Himage from '../../assets/image/jpeg/home-bg2.jpg';
import { device, breakpoints } from "../../utils";
import styled from 'styled-components';

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
  
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;









const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 4.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 36px;
          text-align:center;
      }

`;


const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.1;


`;

const Secti = styled.section`


position: relative;

background-color: #f7f7fb;


@media screen and (max-width: 767px)
{

 }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="#f1f1f1"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
    
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const BlogTreat = () => (
  <>
  
    <Section py={4}  className="position-relative"
     >
      <Container  className="pt-lg-5">
        <Row className="justify-content-center">
          <Col lg="12">
          <Iwrap>


<h3 className="blogheading">Our Treatment Range</h3>
</Iwrap>
            <Box className="text-center" mb={[4, 5]}  data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
            >
        
            </Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/non-surgical-nose-job"><ContentCard
              title="Non Surgical Nose Job"
              color="primary"
              iconName={imgC1}
            /></Link>
          </Col>
    
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/dermal-fillers"><ContentCard
              title="Dermal Fillers"
              color="primary"
              iconName={imgC6}
            /></Link> 
          </Col>
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/anti-wrinkle-treatments"> <ContentCard
              title="Anti-Wrinkle"
              color="success"
              iconName={imgC5}
            /></Link> 
          </Col>
        </Row>
        
        <Row className="justify-content-center">
        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/profhilo"><ContentCard
              title="Profhilo"
              color="primary"
              iconName={imgC7}
            /></Link> 
          </Col>
        
        
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/skin-care"><ContentCard
              title="Skin Care"
              color="primary"
              iconName={imgC4}
            /></Link> 
          </Col>
          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/prp-hair-restoration"> <ContentCard
              title="Hair Restoration"
              color="ash"
              iconName={imgC8}
            /></Link>
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/skinpen-microneedling"> <ContentCard
              title="Microneedling"
              color="ash"
              iconName={imgC2}
            /></Link>
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/laser-hair-removal"> <ContentCard
              title="Laser Hair Removal"
              color="ash"
              iconName={imgC3}
            /></Link>
          </Col>


          <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/other-treatments"><ContentCard
              title="Other Treatments"
              color="primary"
              iconName={imgC9}
            /></Link> 
          </Col>
      
        </Row>
      </Container>
    </Section>

  </>
);

export default BlogTreat;
